import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeMain from "./modules/website/HomeMain";
import HomePage from "./modules/website/Pages/HomePage";
import AboutEzone from "./modules/website/Pages/AboutEzone";
import EzoneShops from "./modules/website/Pages/EzoneShops";
import EzonePartners from "./modules/website/Pages/EzonePartners";
import ContactEzone from "./modules/website/Pages/ContactEzone";
import CustomerLogin from "./modules/website/Pages/CustomerLogin";
import ShopRegister from "./modules/website/Pages/ShopRegister";
import CustomerForgetPassword from "./modules/website/Pages/CustomerForgetPassword";
import DeliveryMain from "./modules/dashboard/DeliveryMain";
import Shipments from "./modules/dashboard/Pages/Shipments";
import PrivateRoute from "./RoutePrivate";
import LoginUser from "./modules/dashboard/Pages/LoginUser";


export default function Router() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeMain />}   >
                    <Route index element={<HomePage />} />
                    <Route path="/about-ezone" element={<AboutEzone />}></Route>
                    <Route path="/our-clients" element={<EzoneShops />}></Route>
                    <Route path="/our-partners" element={<EzonePartners />}></Route>
                    <Route path="/say-hi" element={<ContactEzone />}></Route>
                    <Route path="/shop-login" element={<CustomerLogin />}></Route>
                    <Route path="/register" element={<ShopRegister />}></Route>
                    <Route path="/shop-forgot-password" element={<CustomerForgetPassword />}></Route>

                    {/* <Route path="*" element={<NoPage />} />  */}
                </Route>

                <Route path="delivery/login" element={<LoginUser />} />

                <Route path="delivery" element={<PrivateRoute component={<DeliveryMain />} />} >
                    <Route index element={<Shipments />} />

                </Route>




                <Route path="*" element={<HomeMain />}>
                    <Route path="*" element={<HomePage />} />
                </Route>

            </Routes>
        </BrowserRouter>
    )


}